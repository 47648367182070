import * as React from "react";

import { motion } from "framer-motion";

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)",
  },
  visible: {
    opacity: 1,
    pathLength: 1,
  },
};

const Nav = () => {
  return (
    <div
      style={{ zIndex: "9999" }}
      className="w-screen flex justify-center fixed pointer-events-none	"
    >
      <div style={{ zIndex: "9999" }} className="container" id="nav">
        <motion.svg
          className=" mx-auto item"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 909.28 57.89"
          style={{ zIndex: "9999999" }}
        >
          <g>
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M50.8,17.1c0,9.3-3.5,13.7-11.3,14.8C47,32.7,50.8,36,50.8,43.2V57H35V44c0-6.2-0.8-6.8-8.6-6.8H15.1V57H0V0.9
  h32.1C47.1,0.9,50.8,4.1,50.8,17.1z M28.2,25.7c6.8,0,7.5-0.6,7.5-6.4c0-5.6-0.7-6.2-7.5-6.2H15.1v12.6H28.2z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M103.4,0.9v13h-31v8.6h28.1v12.2H72.4v9.4h31.1V57H57.3V0.9L103.4,0.9z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M145.4,41.1c0-3.2-2.8-4.6-13.4-6.7c-18.4-3.7-23-7.1-23-17.6c0-13.5,5.4-16.9,25.9-16.9s26,4.1,26,20.5h-15.9
  c0-7.5-1.1-8.3-10.1-8.3s-10,0.5-10,4.7c0,3,2.1,4,9.7,5.6c21,4.1,26.6,8,26.6,19.3c0,12.8-5.4,16-26.4,16c-21.5,0-27.1-4-27.1-19.7
  h15.8c0,6.8,1.2,7.5,10.8,7.5S145.4,45.2,145.4,41.1z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M213.1,0.9v13h-31v8.6h28.1v12.2h-28v9.4h31.1V57H167V0.9L213.1,0.9z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M278.1,57h-17.3l-3-11h-21.6l-3.4,11h-16.3L235,0.9h25.1L278.1,57z M254,33.8l-6.7-20.7h-0.4L240,33.8H254z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M332.9,17.1c0,9.3-3.5,13.7-11.3,14.8c7.5,0.8,11.3,4.1,11.3,11.3V57H317V44c0-6.2-0.8-6.8-8.6-6.8h-11.2V57
  H282V0.9h32.1C329.1,0.9,332.9,4.1,332.9,17.1L332.9,17.1z M310.2,25.7c6.8,0,7.5-0.6,7.5-6.4c0-5.6-0.7-6.2-7.5-6.2h-13.1v12.6
  H310.2z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M366.6,57.9c-23,0-28.7-5.8-28.7-28.9s5.8-29,28.7-29s28.7,4.9,28.5,24.5h-15.8c0.1-11.1-1.2-12.3-12.7-12.3
  s-12.9,1.7-12.9,16.8s1.3,16.7,12.9,16.7s12.8-1.2,12.7-12.5h15.8C395.3,52.9,389.6,57.9,366.6,57.9z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M400.9,0.9H416v21h21.6v-21h15.1V57h-15.1V34.8H416V57h-15.1V0.9z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M541.1,44.1h0.6l7.9-43.2h14.7L553.6,57h-25.5l-6.8-43.2h-0.6L514.9,57h-25.7l-11-56.1h15.4l8.1,43.2h0.6
  l5.7-43.2h26.1L541.1,44.1z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M620.8,57h-17.3l-3-11h-21.6l-3.4,11h-16.3l18.5-56.1h25L620.8,57z M596.8,33.8L590,13.1h-0.4l-6.8,20.7
  L596.8,33.8z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M675.6,17.1c0,9.3-3.5,13.7-11.3,14.8c7.5,0.8,11.3,4.1,11.3,11.3V57h-15.8V44c0-6.2-0.8-6.8-8.6-6.8h-11.2V57
  h-15.1V0.9h32.1C671.8,0.9,675.6,4.1,675.6,17.1z M653,25.7c6.8,0,7.5-0.6,7.5-6.4c0-5.6-0.7-6.2-7.5-6.2h-13.1v12.6H653z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M732.9,17.1c0,9.3-3.5,13.7-11.3,14.8c7.5,0.8,11.3,4.1,11.3,11.3V57H717V44c0-6.2-0.8-6.8-8.6-6.8h-11.2V57
  H682V0.9h32.1C729.1,0.9,732.9,4.1,732.9,17.1L732.9,17.1z M710.3,25.7c6.8,0,7.5-0.6,7.5-6.4c0-5.6-0.7-6.2-7.5-6.2h-13.1v12.6
  H710.3z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M797.7,57h-17.3l-3-11h-21.6l-3.4,11h-16.3l18.5-56.1h25.1L797.7,57z M773.6,33.8l-6.7-20.7h-0.4l-6.8,20.7
  H773.6z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M800.9,0.9h14.3l24.8,30.7h0.4V0.9h15.1V57h-14.3l-24.9-30.6H816V57h-15.1L800.9,0.9z"
            />
            <motion.path
              initial="hidden"
              animate="visible"
              variants={icon}
              transition={{
                default: { duration: 2, ease: "easeInOut" },
                fill: { duration: 2, ease: [1, 0, 0.8, 1] },
              }}
              className="st0"
              d="M876.8,13.8h-17.2v-13h49.7v13h-17.3V57h-15.1V13.8z"
            />
          </g>
        </motion.svg>
      </div>
    </div>
  );
};

export default Nav;
