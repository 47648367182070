import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import create from "../images/create.svg";
import epic from "../images/epic.svg";
import interscope from "../images/interscope.svg";
import rca from "../images/rca.svg";

function ClientList() {
  return (
    <div className="section">
      <div className=" mx-auto my-auto  flex flex-col">
        <div className="mx-auto my-auto">
          <h2 className="header-portion bsmt text-center one pb-2 uppercase">
            we’ve worked alongside
          </h2>
          <div className="w-screen">
            <Swiper
              spaceBetween={0}
              slidesPerView={"auto"}
              centeredSlides={true}
              autoplay={{
                delay: 300,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={1500}
              navigation={false}
              modules={[Autoplay, Pagination, Navigation]}
            >
              <SwiperSlide>
                <img src={interscope} className="client-logo mx-auto" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={rca} className="client-logo mx-auto" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={epic} className="client-logo mx-auto" />
              </SwiperSlide>
            </Swiper>
            <p className="caption-animate text-center mt-3 bsmt one text-3xl">
              and more...
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientList;
