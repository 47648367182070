import * as React from "react";
// styles
import Slides from "../components/slides";
import { useRef, useState } from "react";
import { Seo } from "../components/seo";

import Background from "../components/Background";

// markup
const IndexPage = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);

  return (
    <>
      <main ref={myRef}>
        <Seo />
        <Background />
        <Slides />
      </main>
    </>
  );
};

export default IndexPage;
