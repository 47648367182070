import React, { useState } from "react";

import ReactFullpage from "@fullpage/react-fullpage";
import HomePageSlide from "./HomePageSlide";
import AboutResearchWarrant from "./AboutResearchWarrant";
import GalleryPage from "./GalleryPage";
import ClientList from "./ClientList";
import Network from "./Network";
import Nav from "./Nav";
import Contact from "./contact";

function FullPage() {
  const [metricCounter, setMetricCounter] = useState(false);

  function onChange(origin, destination, direction) {
    if (destination.index === 0) {
      document.getElementById("nav").classList.remove("nav-normal");
      document.querySelectorAll("#nav > svg")[0].style.transform = "scale(1)";
    } else if (origin.index === 0) {
      document.getElementById("nav").classList.add("nav-normal");
      document.querySelectorAll("#nav > svg")[0].style.transform = "scale(.5)";
    }
    if (destination.index === 4) {
      setMetricCounter(true);
    }
  }

  // NOTE: if using fullpage extensions/plugins put them here and pass it as props
  const pluginWrapper = () => {
    if (window) {
      require("../fullpage.parallax.min");
    }
  };

  function removeOp() {
    document.getElementById("fp-wrap").classList.remove("opacity-0");
  }

  return (
    <>
      <div style={{ zIndex: "9999" }}>
        <Nav />
      </div>
      <div id="fp-wrap" className="opacity-0" style={{ zIndex: "1" }}>
        <ReactFullpage
          pluginWrapper={pluginWrapper}
          //fullpage options
          parallaxKey={"cmVzZWFyY2h3YXJyYW50LmNvX2R6S2NHRnlZV3hzWVhnPVkxTw=="}
          licenseKey={"FD962852-43E14EB8-B6E18421-EF501A6B"}
          onLeave={onChange.bind(this)}
          scrollingSpeed={1000} /* Options here */
          navigation
          afterRender={removeOp}
          parallax={true}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <HomePageSlide index={1} />
                <AboutResearchWarrant index={2} />
                <GalleryPage index={3} />
                <ClientList index={4} />
                <Network counter={metricCounter} index={5} />
                <Contact index={6} />
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </div>
    </>
  );
}

// markup
const Slides = () => {
  return (
    <>
      <FullPage />
    </>
  );
};

export default Slides;
