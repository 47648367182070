import React from "react";
import CountUp from "react-countup";

function Network(props) {
  const easeOutCirc = (t, b, c, d) => {
    t /= d;
    t--;
    return c * Math.sqrt(1 - t * t) + b;
  };
  return (
    <div className="section">
      <div className="container mx-auto my-auto flex flex-col">
        <div className=" my-auto">
          <h2 className="header-portion bsmt text-center one pb-5">
            WE'RE MADE FOR GROWTH
          </h2>
          <div className="grid sm:gap-y-3 grid-cols-1 md:grid-cols-4">
            <div className="text-center">
              <h3 className="bsmt header2-portion pb-0">Tiktok</h3>
              <h5 className="tiktok bsmt pb-0  header-portion mx-auto my-auto">
                {props.counter ? (
                  <CountUp end={950} useEasing={true} easingFn={easeOutCirc} />
                ) : (
                  0
                )}
                m +
              </h5>
              <p className="bsmt header2-portion pt-0">followers</p>
            </div>
            <div className="text-center">
              <h3 className="bsmt header2-portion pb-0">Instagram</h3>
              <h5 className="tiktok bsmt pb-0  header-portion mx-auto my-auto">
                {props.counter ? (
                  <CountUp end={750} useEasing={true} easingFn={easeOutCirc} />
                ) : (
                  0
                )}
                m +
              </h5>
              <p className="bsmt header2-portion pt-0">followers</p>
            </div>
            <div className="text-center">
              <h3 className="bsmt header2-portion pb-0">Twitter</h3>
              <h5 className="tiktok bsmt pb-0  header-portion mx-auto my-auto">
                {props.counter ? (
                  <CountUp end={125} useEasing={true} easingFn={easeOutCirc} />
                ) : (
                  0
                )}
                m +
              </h5>
              <p className="bsmt header2-portion pt-0">followers</p>
            </div>
            <div className="text-center">
              <h3 className="bsmt header2-portion pb-0">DSPs</h3>
              <h5 className="tiktok bsmt pb-0  header-portion mx-auto my-auto">
                {props.counter ? (
                  <CountUp end={150} useEasing={true} easingFn={easeOutCirc} />
                ) : (
                  0
                )}
                m +
              </h5>
              <p className="bsmt header2-portion pt-0">streams</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Network;
