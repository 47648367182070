import React from "react";
import { useForm, ValidationError } from "@formspree/react";

const Contact = () => {
  const [state, handleSubmit] = useForm("xbjworqp");
  if (state.succeeded) {
    return (
      <div className=" w-screen">
        <h1 className="bsmt h-screen relative mx-auto text-center my-auto justify-center flex flex-col">
          Thanks for joining!
        </h1>
      </div>
    );
  }

  return (
    <div className="section">
      <div className="container mx-auto my-auto flex flex-col">
        <div className="mx-auto my-auto">
          <h2 className="header-portion bsmt text-center one pb-5">
            GET IN CONTACT
          </h2>
          <form onSubmit={handleSubmit}>
            <p>
              <label>
                Your Name:{" "}
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="text"
                  name="name"
                />
              </label>
            </p>
            <p className="mt-3">
              <label>
                Your Email:{" "}
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  type="email"
                  name="email"
                  required={true}
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </label>
            </p>
            <p className="mt-3">
              <label>
                Message:{" "}
                <textarea
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  name="message"
                  required={true}
                ></textarea>
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </label>
            </p>
            <p className="mt-3">
              <button disabled={state.submitting} type="submit">
                SUBMIT
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
