import React from "react";

const AboutResearchWarrant = () => {
  return (
    <div className="section">
      <div className="container mx-auto my-auto flex flex-col">
        <div className="mx-auto my-auto">
          <p className="body-portion ">
            Research Warrant understands the importance of your Digital Image.
            In a space where Perception is Reality and Statistics are King,
            reaching your target audience is equivalent to success, recognition,
            brand worth, and earnings. We can guarantee performance. But how
            it’s done is just as important. With the rise of scams and scandals,
            it’s crucial to find a trustworthy partner to scale your product
            correctly; so you can attract brands and collaborators, all while
            maintaining a credible celebrity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutResearchWarrant;
