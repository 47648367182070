import React from "react";

// Import Swiper React components
import { StaticQuery, graphql } from "gatsby";

const GalleryPage = () => {
  return (
    <div className="section">
      <StaticQuery
        query={graphql`
          {
            allDatoCmsClient {
              edges {
                node {
                  clientTitle
                  descriptionOfProject
                  galleryImage {
                    url
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <div>
            {data.allDatoCmsClient.edges.map((edge) => {
              return (
                <div
                  className="slide featured"
                  style={{
                    backgroundImage: "url(" + edge.node.galleryImage.url + ")",
                  }}
                >
                  <div className="container mx-auto my-auto flex flex-col">
                    <div className="">
                      <div className="h-full absolute top-5">
                        {/* <p className="text-uppercase caption-animate two">
                            {edge.node.descriptionOfProject}
                          </p> */}
                      </div>
                    </div>
                    <div className="h-full flex items-center justify-center">
                      <h2 className="header-portion text-center mt-3 bsmt ">
                        CAMPAIGN SPOTLIGHT
                        <br />
                        {edge.node.clientTitle}
                      </h2>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      position: "absolute",
                      backgroundColor: "rgba(0,0,0,.3)",
                      top: "0",
                      zIndex: "-1",
                    }}
                  />
                </div>
              );
            })}
          </div>
        )}
      ></StaticQuery>
    </div>
  );
};

export default GalleryPage;
